import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });

  }

  click(){
    this.router.navigateByUrl('/home')
  }

  images = [
    'assets/img/owl/1.png',
    'assets/img/owl/2.png',
    'assets/img/owl/3.png',
    'assets/img/owl/4.png',
    'assets/img/owl/5.png',
    'assets/img/owl/6.png',
    'assets/img/owl/7.png',
    'assets/img/owl/8.png',
    'assets/img/owl/9.png',
    'assets/img/owl/10.png',
  ];
  SlideOptions = { items: 4, dots: false, nav: false ,autoplay:500,loop:true};
  CarouselOptions = { items: 4, dots: false, nav: false ,autoplay:500,loop:true};

}
