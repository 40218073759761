<div class="container-fullwidth">
  <app-nav></app-nav>
  <div class=" col-sm-12 col-md-12 col-xl-12 banner" id="banner" style="background-image:url('assets/img/background/BG-2.jpeg');" >
    <div class="row">

      <div class="col-md-2"></div>
      <div class="col-xl-5 mt-5 white" style="padding-top: 10%;padding-bottom: 10%;">
        <h2 [translate]="'CONTACT.banner_head'"></h2>
        <p [translate]="'CONTACT.banner_detail'"></p>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

  <div class="col-md-12 text-center py-5">
    <h2 [translate]="'CONTACT.topic'"></h2>

    <div class="row text-center">
      <div class="col-md-2"></div>
      <div class="col-md-12 col-lg-12 col-xl-8 pt-5">
        <div class="row" style="justify-content: center;">
          <div class="col-md-3 m-1 call-border" >
            <p><img src="assets/img/contract/1.png" alt="" ></p>
            <!-- <img src="assets/img/contract/call-outline.svg" width="40px" height="auto" alt="" style="position: absolute;top:0;left: 0;"> -->
            <p>+66 (2) 245 3936</p>
          </div>
          <div class="col-md-3 m-1 location-border">
            <p><img src="assets/img/contract/2.png" alt=""></p>
            <p [translate]="'FOOTER.location'"></p>
          </div>
          <div class="col-md-3 m-1 email-border">
            <p><img src="assets/img/contract/3.png" alt=""></p>
            <p>support@perumaltt.com</p>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

  <div class="map-wraper">

    <div id="google_map" class="p-md-5" style="position: relative;">
      <agm-map [mapDraggable]="true" [scrollwheel]="false" [zoom]="18" [latitude]="lat" [longitude]="lng">
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
      </agm-map>
     </div>

     <div class="m-5 over_left">
       <div class="col-md-12 shadow p-3">
        <div class="row">
          <div class="col-md-auto d-none d-xl-block">
            <p>KINT App - Perumal Technology <br> & Telecommunication Co., Ltd.</p>
            <a target="_blank" href="https://www.google.com/maps/place/KINT+App+-+Perumal+Technology+%26+Telecommunication+Co.,+Ltd./@13.7591287,100.5555169,18.5z/data=!4m5!3m4!1s0x0:0x325b0d49db51e7fe!8m2!3d13.7591198!4d100.5563375?hl=en">
              View larger map
            </a>
          </div>
          <div class="col-md-auto text-center pl-xl-0">
            <a target="_blank" href="https://www.google.com/maps/dir//13.7591198,100.5563375/@13.75912,100.556338,17z?hl=en">
              <img [src]="'assets/img/direction.png'" width="40px" height="40px" alt=""> <br>
            Direction</a>
          </div>
        </div>
       </div>
     </div>

     <div class="m-5 over_right d-none d-xl-block">
      <div class="col-md-12 shadow p-5 ml-auto mr-5">
        <form action="">

          <div class="from-group text-center">
            <h2>Get In Touch</h2>
          </div>

          <div class="form-group">
            <label for="name">Your Name (Required)</label>
            <input type="text" class="form-control">
          </div>

          <div class="form-group">
            <label for="phone">Phone Number (required)</label>
            <input type="text" class="form-control">
          </div>

          <div class="form-group">
            <label for="email">Your Email (required)</label>
            <input type="text" class="form-control">
          </div>

          <div class="form-group">
            <label for="company_name">Company Name (required)</label>
            <input type="text" class="form-control">
          </div>

          <div class="form-group">
            <label for="company_name">Company Size (required)</label>
            <input type="text" class="form-control">
          </div>

          <div class="form-group">
            <label for="company_name">Country Name (required)</label>
            <input type="text" class="form-control">
          </div>

          <div class="form-group">
            <label for="company_name">Tell Us Why You Are Here (required)</label>
            <input type="text" class="form-control">
          </div>

          <div class="form-group text-center">
            <button class="btn btn-success">Send</button>
          </div>

        </form>
      </div>
    </div>
  </div>





  <app-footer></app-footer>
</div>
