import { Component } from '@angular/core';
import { HostListener } from '@angular/core';
import { faPhoneAlt,faEnvelope,faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faApple,faGooglePlay,faFacebook,faTwitter,faInstagram } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [

  ]
})

export class AppComponent {
  faPhoneAlt = faPhoneAlt;
  faEnvelope=faEnvelope;
  faMapMarkerAlt=faMapMarkerAlt;
  faApple= faApple;
  faGooglePlay = faGooglePlay;
  faFacebook=faFacebook;
  faTwitter=faTwitter;
  faInstagram=faInstagram;
  title = 'kint-app';
  banner2 = 'assets/img/banner2.png';
  active = 'promote_1';

  features_img = ['spin.png','gif'].map((img)=>{
    `assets/img/features_img/${img}`
    // console.log(img)
  });

  // OWL
  images = [
    'assets/img/owl/1.png',
    'assets/img/owl/2.png',
    'assets/img/owl/3.png',
    'assets/img/owl/4.png',
    'assets/img/owl/5.png',
    'assets/img/owl/6.png',
    'assets/img/owl/7.png',
    'assets/img/owl/8.png',
    'assets/img/owl/9.png',
    'assets/img/owl/10.png',
  ];
  SlideOptions = { items: 5, dots: false, nav: false ,autoplay:1000,loop:true};
  CarouselOptions = { items: 5, dots: false, nav: false ,autoplay:1000,loop:true};
  // OWL

}
