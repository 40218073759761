import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { faPhoneAlt,faEnvelope,faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faApple,faGooglePlay,faFacebook,faTwitter,faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private router : Router) { }

  faPhoneAlt = faPhoneAlt;
  faEnvelope=faEnvelope;
  faMapMarkerAlt=faMapMarkerAlt;
  faApple= faApple;
  faGooglePlay = faGooglePlay;
  faFacebook=faFacebook;
  faTwitter=faTwitter;
  faInstagram=faInstagram;
  title = 'kint-app';
  banner2 = 'assets/img/banner2.png';
  active = 'promote_1';

    // OWL
    images = [
      'assets/img/owl/1.png',
      'assets/img/owl/2.png',
      'assets/img/owl/3.png',
      'assets/img/owl/4.png',
      'assets/img/owl/5.png',
      'assets/img/owl/6.png',
      'assets/img/owl/7.png',
      'assets/img/owl/8.png',
      'assets/img/owl/9.png',
      'assets/img/owl/10.png',
    ];
    SlideOptions = { items: 4, dots: false, nav: false ,autoplay:500,loop:true,responsive:{

    }};
    CarouselOptions = { items: 4, dots: false, nav: false ,autoplay:500,loop:true ,responsive:{

    }};
    // OWL

    scrollToElement($element): void {
      document.getElementById($element).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

}
