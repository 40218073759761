    <!-- Footer -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-12 col-lg-12 col-xl-8">
          <div class="row mb-5">
            <div class="col-md-12 my-5 text-center">
              <div class="btns-group mt-5">
                <a target="_blank" href="https://apps.apple.com/th/app/kint/id1142166863" class="btn store-btn bg-default-blue m-2 white">
                  <span class="icon"><fa-icon [icon]="faApple"></fa-icon></span>
                    <strong style="display: inline-block;">
                      <small>Available on</small><br>
                        Apple Store
                    </strong>
                  </a>

                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.kintapp.kint&hl=en" class="btn store-btn bg-default-pink m-2 white">
                  <span class="icon"><fa-icon [icon]="faGooglePlay"></fa-icon></span>
                    <strong style="display: inline-block;">
                      <small>Available on</small><br>
                        Google Play
                    </strong>
                  </a>
              </div>
            </div>
            <div class="col-md-6 ml-md-3">
              <img class="img-fluid my-4" src="assets/img/logo-high.png" width="75px" height="auto" alt="">
              <p [translate]="'FOOTER.kint_detail'"></p>

                <div class="row">
                  <a href="https://www.facebook.com/kint.app.96/" target="_blank" class="btn" style="color:#1877f2"><fa-icon [icon]="faFacebook"></fa-icon></a>
                  <a href="https://twitter.com/KINTApp" target="_blank" class="btn" style="color: #00acee;"><fa-icon [icon]="faTwitter"></fa-icon></a>
                  <a href="https://www.instagram.com/kintapplication/" target="_blank" class="btn" style="color: #8a3ab9;"><fa-icon [icon]="faInstagram"></fa-icon></a>
                </div>
            </div>

            <!-- <div class="col-md-2"></div> -->

            <div class="col-md-4 mt-3 ml-auto mr-md-3">
              <h3 [translate]="'FOOTER.address'"></h3>

                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-auto align-items-center"><small style="color: #138afd;"><fa-icon [icon]="faPhoneAlt"></fa-icon></small></div>
                    <div class="col-md-8 p-0"><p>(+66) 2 245 3936</p></div>
                  </div>


                  <div class="row">
                    <div class="col-md-auto align-items-center"><small style="color: #138afd;"><fa-icon [icon]="faPhoneAlt"></fa-icon></small></div>
                    <div class="col-md-8 p-0"><p>(+66) 63 191 8474</p></div>
                  </div>

                  <div class="row">
                    <div class="col-md-auto align-items-center"><small style="color: #138afd;"><fa-icon [icon]="faEnvelope"></fa-icon></small></div>
                    <div class="col-md-8 p-0"><p>kamonruji@perumaltt.com</p></div>
                  </div>

                  <div class="row">
                    <div class="col-md-auto align-items-center"><small style="color: #138afd;"><fa-icon [icon]="faMapMarkerAlt"></fa-icon></small></div>
                    <div class="col-md-8 p-0"><p [translate]="'FOOTER.location'"></p></div>
                  </div>
                </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-md-6 py-5 footer-text">
              Copyright @ 2021 KINT App all right reserved.
            </div>
            <div class="col-md-6 py-5 text-right white-space-nowrap">
              <!-- <div class="row"> -->
                <!-- <a class="footer-text ml-auto mr-3" href="">Career</a>
                <a class="footer-text mx-3" href="">Terms Of Use</a> -->
                <a routerLink="/terms-of-service" class="footer-text mx-3">Terms Of Service</a>
                <a routerLink="/privacy-policy" class="footer-text mx-3" href="">Privacy Policy</a>
              <!-- </div> -->
            </div>
          </div>


        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
    <!-- Footer -->
