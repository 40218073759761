<div class="container-fullwidth body-con">
  <app-nav></app-nav>

  <div class=" col-sm-12 col-md-12 col-xl-12 banner" id="banner" style="background-image:url('assets/img/background/BG-2.jpeg');" >
    <div class="row">

      <div class="col-md-2"></div>
      <div class="col-xl-5 mt-5 white" style="padding-top: 10%;padding-bottom: 10%;">
        <h2 [translate]="'SOLUTION.banner_head'"></h2>
        <p [translate]="'SOLUTION.banner_detail'"></p>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

    <!-- Owl Carousel -->
    <div class="container-fluid my-5 d-none d-md-block">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-12 col-lg-12 col-xl-8">
          <div class="col-md-12 text-center py-5" >
          </div>
          <div class="col-md-12 py-5 align-items-center">
            <owl-carousel [options]="SlideOptions" [carouselClasses]="['owl-theme', 'sliding']" >
              <div class="item" *ngFor="let img of images">
              <div style="align-content: center">
                <img style="height: 50px;width:auto"src={{img}}/>
              </div>
              </div>
            </owl-carousel>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
    <!-- Owl Carousel -->

    <div class="container-fluid my-5">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-12 col-lg-12 col-xl-8">
          <div class="col-md-12 text-center">
            <h2 [translate]="'SOLUTION.banner_head'"></h2>
          </div>

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="my-5">
                  <h3 [translate]="'SOLUTION.solution_1_head'"></h3>
                  <p [translate]="'SOLUTION.solution_1_detail'"></p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="my-5">
                  <h3 [translate]="'SOLUTION.solution_2_head'"></h3>
                  <p [translate]="'SOLUTION.solution_2_detail'"></p>
                </div>
              </div>

            </div>
          </div>

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="my-5">
                  <h3 [translate]="'SOLUTION.solution_3_head'"></h3>
                  <p [translate]="'SOLUTION.solution_3_detail'"></p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="my-5">
                  <h3 [translate]="'SOLUTION.solution_4_head'"></h3>
                  <p [translate]="'SOLUTION.solution_4_detail'"></p>
                </div>
              </div>

            </div>
          </div>

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="my-5">
                  <h3 [translate]="'SOLUTION.solution_5_head'"></h3>
                  <p [translate]="'SOLUTION.solution_5_detail'"></p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="my-5">
                  <h3 [translate]="'SOLUTION.solution_6_head'"></h3>
                  <p [translate]="'SOLUTION.solution_6_detail'"></p>
                </div>
              </div>

            </div>
          </div>

          <div class="col-md-12">
            <div class="row" style="justify-content: center;">
              <div class="col-md-6">
                <div class="my-5">
                  <h3 [translate]="'SOLUTION.solution_7_head'"></h3>
                  <p [translate]="'SOLUTION.solution_7_detail'"></p>
                </div>
              </div>

            </div>
          </div>


        <div class="col-md-12 text-center pt-5">
          <span [translate]="'SOLUTION.contact'"></span> <br>
          <a href="mailto:support@perumaltt.com"> support@perumaltt.com </a> <br>
          <span [translate]="'SOLUTION.call'"></span>
          <!-- <a href=" tel:+66 (2) 245 3936 | 0818234883">+66 (2) 245 3936 | 0818234883</a> -->
          <a href=" tel:+6622453936">+66 (2) 245 3936</a> |
          <a href=" tel:+66818234883">+66 81 823 4883</a>
        </div>

        </div>
        <div class="col-md-2"></div>
      </div>
    </div>

  <app-footer></app-footer>
</div>
