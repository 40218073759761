
<div class="container-fullwidth body-con">
  <app-nav></app-nav>

  <div class=" col-sm-12 col-md-12 col-xl-12 banner" id="banner" style="background-image:url('assets/img/background/BG-2.jpeg');" >
    <div class="row">

      <div class="col-md-2"></div>
      <div class="col-xl-5 mt-5 white" style="padding-top: 10%;padding-bottom: 10%;">
        <h2 [translate]="'ABOUT.banner_head'"></h2>
        <p [translate]="'ABOUT.banner_detail'"></p>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-12 col-lg-12 col-xl-10">

        <!-- <div class="media">
          <img class="rounded" [src]="'assets/img/about/PM.jpg'" alt="" width="100%" height="auto">
          <div class="media-body">
            <h2 [translate]="'ABOUT.topic_head'"></h2>
            <p>
              <span [translate]="'ABOUT.topic_detail_1'"></span>
            </p>
            <p>
              <span [translate]="'ABOUT.topic_detail_2'"></span>
            </p>
          </div>
        </div> -->

        <div class="row">

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 p-5 align-self-center mx-auto">
            <img class="rounded" [src]="'assets/img/about/PM_1.jpg'" alt="" width="100%" height="auto">
          </div>

          <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 pt-5 align-self-center" >
            <h2 [translate]="'ABOUT.topic_head'"></h2>
            <div>
              <p>
                <span [translate]="'ABOUT.topic_detail_1'"></span>
              </p>
              <p>
                <span [translate]="'ABOUT.topic_detail_2'"></span>
              </p>
            </div>


          </div>
        </div>

        <div class="col-md-12" style="    text-align: -webkit-center;">
          <div class="col-md-6">
            <img [src]="'assets/img/about/license.png'" alt="" width="100%" height="auto">
            <h3 [translate]="'ABOUT.pm_name'"></h3>
            <p [translate]="'ABOUT.pm_detail'"></p>
          </div>
        </div>

        <div class="col-md-12 text-center pt-5">
          <h2 [translate]="'ABOUT.mission_head'"></h2>
          <p class="text-center" [translate]="'ABOUT.mission_detail'"></p>
        </div>

        <div class="col-md-12 text-center pt-5">
          <h2 [translate]="'ABOUT.vision_head'"></h2>
          <p class="text-center" [translate]="'ABOUT.vision_detail'"></p>
        </div>

      </div>
      <div class="col-md-1"></div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
