import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  translate: TranslateService;


  constructor(translate: TranslateService) {
    this.translate = translate;
    this.translate.setDefaultLang('en');
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);

  }
  // When the user scrolls down 20px from the top of the document, show the button


  scrollToElement($element): void {
    document.getElementById($element).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    this.bgNavbarChange();
    // this.scrollFunction();
  }

  // scrollFunction() {
  //   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
  //       document.getElementById("backTop").style.display = "block";
  //   } else {
  //       document.getElementById("backTop").style.display = "none";
  //   }
  // }

  bgNavbarChange(status = true){
    let element = document.querySelector('.navbar');
    let ele = document.getElementsByClassName("navbar-collapse");

    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("backTop").style.display = "block";
    } else {
        document.getElementById("backTop").style.display = "none";
    }

    if(ele[0].classList.contains('show') == status){
      // console.log(1)

      element.classList.add('kint_menu');
    }else{
      // console.log(0)
      if (window.pageYOffset > element.clientHeight) {
        element.classList.add('kint_menu');
      }else{
        element.classList.remove('kint_menu');
      }

    }
  }

  toggleNavbar = true;

  ngOnInit(): void {
  }

}
