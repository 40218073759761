import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { FeaturesComponent } from './features/features.component';
import { MainComponent } from './main/main.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { AboutComponent } from './about/about.component';
import { PricingComponent } from './pricing/pricing.component';
import { PartnerComponent } from './partner/partner.component';
import { ContactComponent } from './contact/contact.component';
import { TermsofserviceComponent } from './termsofservice/termsofservice.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { PrivacypolicyPadaComponent } from './privacypolicy-pada/privacypolicy-pada.component';

const routes: Routes = [
  // { path: 'home', component: AppComponent },
  { path: '', component: MainComponent},
  { path: 'home', component: MainComponent},
  { path : 'features' , component:FeaturesComponent},
  { path : 'solutions', component:SolutionsComponent},
  { path : 'about' , component:AboutComponent},
  { path : 'pricing', component:PricingComponent},
  { path : 'partner', component:PartnerComponent},
  { path : 'contact', component:ContactComponent},
  { path : 'terms-of-service', component:TermsofserviceComponent},
  { path : 'terms-of-service-mobile-th', component:TermsofserviceComponent},
  { path : 'privacy-policy' , component:PrivacypolicyComponent},
  { path : 'privacy-policy-pada' ,component:PrivacypolicyPadaComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
