<div class="container-fullwidth body-con">

  <app-nav></app-nav>



  <div class=" col-sm-12 col-md-12 col-xl-12 banner" id="banner"
    style="background-image:url('assets/img/background/BG-Shape.jpg');">
    <div class="row">

      <div class="col-xl-1"></div>
      <div class="col-xl-5 col-md-6 mt-5 pt-lg-5 white pl-lg-5 pb-md-5 mb-md-5">
        <h2 class="align-middle text-center" [translate]="'HOME.banner_head'"></h2>
        <p class="d-none d-md-block" [translate]="'HOME.banner_detail'"></p>

        <div class="row btns-group pb-md-5 mb-md-5" style="justify-content: center;">
          <a target="_blank" href="https://apps.apple.com/th/app/kint/id1142166863"
            class="btn store-btn bg-default-blue m-2 white">
            <span class="icon">
              <fa-icon [icon]="faApple"></fa-icon>
            </span>
            <strong style="display: inline-block;">
              <small>Available on</small><br>
              Apple Store
            </strong>
          </a>

          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.kintapp.kint&hl=en"
            class="btn store-btn bg-default-pink m-2 white">
            <span class="icon">
              <fa-icon [icon]="faGooglePlay"></fa-icon>
            </span>
            <strong style="display: inline-block;">
              <small>Available on</small><br>
              Google Play
            </strong>
          </a>
        </div>

      </div>
      <div class="col-xl-5 col-md-6 text-center d-none d-md-block">
        <div style="position: relative;top: 0;left: 0;">
          <img [src]="'assets/img/banner/banner2.png'" width="50%" height="auto"
            style="  position: relative;top: 0;left: 0;border-radius: 35px;">
          <img class="right-left" [src]="'assets/img/banner/banner_chat.png'" width="50%" height="auto"
            style="position: absolute;top: 5%;left: 5%;">
        </div>
      </div>
      <div class="col-xl-1"></div>

    </div>
  </div>

  <div class="col-md-12 col-lg-12 col-xl-12 px-md-5 pt-5 my-5">
    <div class="text-center">
      <h2 [translate]="'HOME.topic'"></h2>
      <p [translate]="'HOME.detail'"></p>
    </div>
  </div>

  <!-- FEATURES -->
  <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-12 col-lg-12 col-xl-10 my-5 pt-5">
        <div class="container-fluid p-0">
          <div class="row px-lg-5">

            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8" style="align-self: center;">
              <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">

                <li ngbNavItem="promote_1" class="my-1">
                  <a ngbNavLink>
                    <div class="row">
                      <div class="col-md-12 col-xl-12">
                        <span class="align-items-center my-2" style="display: flex;">
                          <img class="mx-1" [src]="'assets/img/promote_img/icon1.png'" width="50px" height="auto">
                          <h3 class="m-0" [translate]="'HOME.feature_1_head'"></h3>
                        </span>
                        <p [translate]="'HOME.feature_1_detail'"></p>
                      </div>
                    </div>
                  </a>
                  <ng-template ngbNavContent>
                    <img [src]="'assets/img/promote_img/promote1.png'" width="100%" height="auto"
                      style="object-fit: cover;">
                  </ng-template>
                </li>

                <li ngbNavItem="promote_2" class="my-1">
                  <a ngbNavLink>
                    <div class="row">
                      <div class="col-md-12 col-xl-12">
                        <span class="align-items-center my-2" style="display: flex;">
                          <img class="mx-1" [src]="'assets/img/promote_img/icon1.png'" width="50px" height="auto">
                          <h3 class="m-0" [translate]="'HOME.feature_2_head'"></h3>
                        </span>
                        <p [translate]="'HOME.feature_2_detail'"></p>
                      </div>
                    </div>
                  </a>
                  <ng-template ngbNavContent>
                    <img [src]="'assets/img/promote_img/promote2.png'" width="100%" height="auto">
                  </ng-template>
                </li>

                <li ngbNavItem="promote_3" class="my-1">
                  <a ngbNavLink>
                    <div class="row">
                      <div class="col-md-12 col-xl-12">
                        <span class="align-items-center my-2" style="display: flex;">
                          <img class="mx-1" [src]="'assets/img/promote_img/icon2.png'" width="50px" height="auto">
                          <h3 class="m-0" [translate]="'HOME.feature_3_head'"></h3>
                        </span>
                        <p [translate]="'HOME.feature_3_detail'"></p>
                      </div>
                    </div>
                  </a>
                  <ng-template ngbNavContent>
                    <img [src]="'assets/img/promote_img/promote3.png'" width="100%" height="auto">
                  </ng-template>
                </li>

                <li ngbNavItem="promote_4" class="my-1">
                  <a ngbNavLink>
                    <div class="row">
                      <div class="col-md-12 col-xl-12">
                        <span class="align-items-center my-2" style="display: flex;">
                          <img class="mx-1" [src]="'assets/img/promote_img/icon3.png'" width="50px" height="auto">
                          <h3 class="m-0" [translate]="'HOME.feature_4_head'"></h3>
                        </span>
                        <p [translate]="'HOME.feature_4_detail'"></p>
                      </div>
                    </div>
                  </a>
                  <ng-template ngbNavContent>
                    <img [src]="'assets/img/promote_img/promote4.png'" width="100%" height="auto">
                  </ng-template>
                </li>
              </ul>
            </div>

            <div [ngbNavOutlet]="nav" class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center d-none d-lg-block"
              style="place-self: center;"></div>
          </div>
        </div>

      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
  <!-- FEATURES -->

  <!-- Why should you choose KINT -->
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-12 col-lg-12 col-xl-10">
        <h2 class="text-center px-md-5" [translate]="'HOME.reason_topic'"></h2>
        <p class="text-center" [translate]="'HOME.reason_detail'"></p>
        <div class="row px-lg-5">
          <div class="col-md-12 col-lg-6 col-xl-6 align-self-center">

            <ul class="list-unstyled mt-5">
              <li class="media">
                <img [src]="'assets/img/features_img/icon1.png'">
                <div class="media-body ml-4">
                  <h4 [translate]="'HOME.reason_1_head'"></h4>
                  <p><span [translate]="'HOME.reason_1_detail'"></span></p>
                </div>
              </li>
              <li class="media my-4">
                <img [src]="'assets/img/features_img/icon2.png'">
                <div class="media-body ml-4">
                  <div></div>
                  <h4 [translate]="'HOME.reason_2_head'"></h4>
                  <p><span [translate]="'HOME.reason_2_detail'"></span></p>
                </div>
              </li>
              <li class="media">
                <img [src]="'assets/img/features_img/icon3.png'">
                <div class="media-body ml-4">
                  <h4 [translate]="'HOME.reason_3_head'"></h4>
                  <p><span [translate]="'HOME.reason_3_detail'"></span></p>
                </div>
              </li>
            </ul>

          </div>

          <div class="col-md-12 col-lg-6 col-xl-6 p-md-5" style="place-self: center;">
            <div style="position: relative;top: 0;left: 0;">
              <img [src]="'assets/img/features_img/spin.png'" class="spin" width="100%" height="auto"
                style="  position: relative;top: 0;left: 0;">
              <img [src]="'assets/img/features_img/features.gif'" width="80%" height="auto"
                style="  position: absolute;top: 10%;left: 20%;">
              <img [src]="'assets/img/features_img/features.gif'" width="80%" height="auto"
                style="  position: absolute;top: 25%;left: 0;">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>

  <!-- Why should you choose KINT -->

  <!-- Pricing Packages -->
  <div class="container-fluid mt-5 mb-5 pt-5">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-12 col-lg-12 col-xl-8">
        <div class="col-md-12 text-center">
          <h2 [translate]="'HOME.pricing_head'"></h2>
          <p [translate]="'HOME.pricing_detail'"></p>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
                    <span>most popular</span>
                </div> -->
            <!-- <div class="card-title">
              <h4>SMALL</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_1_1'"></span><br>
                <span [translate]="'HOME.price_1_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_1_detail_1'"></li>
                <li [translate]="'HOME.price_1_detail_2'"></li>
                <li [translate]="'HOME.price_1_detail_3'"></li>
                <li [translate]="'HOME.price_1_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
        <!-- <div class="card popular">
                <div class="card-ribbon">
                    <span>most popular</span>
                </div>
                <div class="card-title">
                    <h3>Pro</h3>
                    <h4>Maecenas ut justo molestie, pharetra arcu ac, mollis est.</h4>
                </div>
                <div class="card-price">
                    <h1>
                        <sup></sup>
                        15
                        <small>month</small>
                    </h1>
                </div>
                <div class="card-description">
                    <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Pellentesque hendrerit</li>
                        <li>Aliquam at orci aliquam</li>
                        <li>Praesent non sapien laoreet</li>
                    </ul>
                </div>
                <div class="card-action">
                    <button type="button">Get Pro</button>
                </div>
            </div> -->
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
                  <span>most popular</span>
              </div> -->
            <!-- <div class="card-title">
              <h4>MEDIUM</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_2_1'"></span><br>
                <span [translate]="'HOME.price_2_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_2_detail_1'"></li>
                <li [translate]="'HOME.price_2_detail_2'"></li>
                <li [translate]="'HOME.price_2_detail_3'"></li>
                <li [translate]="'HOME.price_2_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
                <span>most popular</span>
            </div> -->
            <!-- <div class="card-title">
              <h4>LARGE</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_3_1'"></span><br>
                <span [translate]="'HOME.price_3_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_3_detail_1'"></li>
                <li [translate]="'HOME.price_3_detail_2'"></li>
                <li [translate]="'HOME.price_3_detail_3'"></li>
                <li [translate]="'HOME.price_3_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
              <span>most popular</span>
          </div> -->
            <!-- <div class="card-title">
              <h4>XL</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_4_1'"></span><br>
                <span [translate]="'HOME.price_4_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_4_detail_1'"></li>
                <li [translate]="'HOME.price_4_detail_2'"></li>
                <li [translate]="'HOME.price_4_detail_3'"></li>
                <li [translate]="'HOME.price_4_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
            <span>most popular</span>
        </div> -->
            <!-- <div class="card-title">
              <h4>XL</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_5_1'"></span><br>
                <span [translate]="'HOME.price_5_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_5_detail_1'"></li>
                <li [translate]="'HOME.price_5_detail_2'"></li>
                <li [translate]="'HOME.price_5_detail_3'"></li>
                <li [translate]="'HOME.price_5_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
            <span>most popular</span>
        </div> -->
            <!-- <div class="card-title">
              <h4>XL</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_6_1'"></span><br>
                <span [translate]="'HOME.price_6_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_6_detail_1'"></li>
                <li [translate]="'HOME.price_6_detail_2'"></li>
                <li [translate]="'HOME.price_6_detail_3'"></li>
                <li [translate]="'HOME.price_6_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Pricing Packages -->


  <!-- Owl Carousel -->
  <div class="container-fluid mt-5 d-none d-md-block">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-12 col-lg-12 col-xl-8">
        <div class="col-md-12 text-center py-5">
          <h2 [translate]="'HOME.among'"></h2>
        </div>
        <div class="col-md-12 py-5 align-items-center">
          <owl-carousel [options]="SlideOptions" [carouselClasses]="['owl-theme', 'sliding']">
            <div class="item" *ngFor="let img of images">
              <div style="align-content: center">
                <img style="height: 50px;width:auto" src={{img}} />
              </div>
            </div>
          </owl-carousel>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
  <!-- Owl Carousel -->



  <!-- So, start communicating easily and get started with KINT! -->
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-12 col-lg-12 col-xl-8">
        <div class="row align-items-center">
          <div class="col-md-6 ">
            <h2 [translate]="'HOME.conclude'"></h2>

            <div class="row btns-group mt-5" style="justify-content: center;">
              <a target="_blank" href="https://apps.apple.com/th/app/kint/id1142166863"
                class="btn store-btn bg-default-blue m-2 white">
                <span class="icon">
                  <fa-icon [icon]="faApple"></fa-icon>
                </span>
                <strong style="display: inline-block;">
                  <small>Available on</small><br>
                  Apple Store
                </strong>
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.kintapp.kint&hl=en"
                class="btn store-btn bg-default-pink m-2 white">
                <span class="icon">
                  <fa-icon [icon]="faGooglePlay"></fa-icon>
                </span>
                <strong style="display: inline-block;">
                  <small>Available on</small><br>
                  Google Play
                </strong>
              </a>
            </div>
          </div>

          <div class="col-md-6">
            <div style="position: relative;top: 0;left: 0;">
              <img [src]="'assets/img/features_img/spin.png'" class="spin" width="100%" height="auto"
                style="  position: relative;top: 0;left: 0;">
              <img [src]="'assets/img/group.png'" width="50%" height="auto"
                style="position: absolute;top: 0;left: 25%;">
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-2"></div>

    </div>
  </div>
  <!-- So, start communicating easily and get started with KINT! -->


  <!-- Get the latest updates ! mailed to you -->
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-12 col-lg-12 col-xl-8">

        <div class="col-md-12 text-center my-5">
          <h2>Get the latest updates ! mailed to you </h2>
          <input type="text" class="form-control my-4" placeholder="Enter Email Address">
          <button class="btn btn-success p-3" [translate]="'HOME.price_submit'"></button>
        </div>



      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
  <!-- Get the latest updates ! mailed to you -->

  <app-footer></app-footer>

</div>
