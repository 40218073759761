import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})
export class SolutionsComponent implements OnInit {

  constructor(private router: Router) { }

      // OWL
      images = [
        'assets/img/owl/1.png',
        'assets/img/owl/2.png',
        'assets/img/owl/3.png',
        'assets/img/owl/4.png',
        'assets/img/owl/5.png',
        'assets/img/owl/6.png',
        'assets/img/owl/7.png',
        'assets/img/owl/8.png',
        'assets/img/owl/9.png',
        'assets/img/owl/10.png',
      ];
      SlideOptions = { items: 4, dots: false, nav: false ,autoplay:500,loop:true};
      CarouselOptions = { items: 4, dots: false, nav: false ,autoplay:500,loop:true};
      // OWL

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

}
