<div class="container-fullwidth">
  <app-nav></app-nav>

  <div class=" col-sm-12 col-md-12 col-xl-12 banner" id="banner" style="background-image:url('assets/img/background/BG-2.jpeg');">
    <div class="row">

      <div class="col-md-2"></div>
      <div class="col-xl-5 mt-5 white" style="padding-top: 10%;padding-bottom: 10%;">
        <h2 [translate]="'PRICING.banner_head'"></h2>
        <p [translate]="'PRICING.banner_detail'"></p>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

  <!-- Pricing Packages -->
  <div class="container-fluid mt-5 mb-5 pt-5">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-12 col-lg-12 col-xl-8">
        <div class="col-md-12 text-center">
          <h2 [translate]="'PRICING.topic_head'"></h2>
          <p [translate]="'PRICING.topic_detail'"></p>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
                    <span>most popular</span>
                </div> -->
            <!-- <div class="card-title">
              <h4>SMALL</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_1_1'"></span><br>
                <span [translate]="'HOME.price_1_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_1_detail_1'"></li>
                <li [translate]="'HOME.price_1_detail_2'"></li>
                <li [translate]="'HOME.price_1_detail_3'"></li>
                <li [translate]="'HOME.price_1_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
        <!-- <div class="card popular">
                <div class="card-ribbon">
                    <span>most popular</span>
                </div>
                <div class="card-title">
                    <h3>Pro</h3>
                    <h4>Maecenas ut justo molestie, pharetra arcu ac, mollis est.</h4>
                </div>
                <div class="card-price">
                    <h1>
                        <sup></sup>
                        15
                        <small>month</small>
                    </h1>
                </div>
                <div class="card-description">
                    <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Pellentesque hendrerit</li>
                        <li>Aliquam at orci aliquam</li>
                        <li>Praesent non sapien laoreet</li>
                    </ul>
                </div>
                <div class="card-action">
                    <button type="button">Get Pro</button>
                </div>
            </div> -->
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
                  <span>most popular</span>
              </div> -->
            <!-- <div class="card-title">
              <h4>MEDIUM</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_2_1'"></span><br>
                <span [translate]="'HOME.price_2_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_2_detail_1'"></li>
                <li [translate]="'HOME.price_2_detail_2'"></li>
                <li [translate]="'HOME.price_2_detail_3'"></li>
                <li [translate]="'HOME.price_2_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
                <span>most popular</span>
            </div> -->
            <!-- <div class="card-title">
              <h4>LARGE</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_3_1'"></span><br>
                <span [translate]="'HOME.price_3_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_3_detail_1'"></li>
                <li [translate]="'HOME.price_3_detail_2'"></li>
                <li [translate]="'HOME.price_3_detail_3'"></li>
                <li [translate]="'HOME.price_3_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
              <span>most popular</span>
          </div> -->
            <!-- <div class="card-title">
              <h4>XL</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_4_1'"></span><br>
                <span [translate]="'HOME.price_4_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_4_detail_1'"></li>
                <li [translate]="'HOME.price_4_detail_2'"></li>
                <li [translate]="'HOME.price_4_detail_3'"></li>
                <li [translate]="'HOME.price_4_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
            <span>most popular</span>
        </div> -->
            <!-- <div class="card-title">
              <h4>XL</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_5_1'"></span><br>
                <span [translate]="'HOME.price_5_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_5_detail_1'"></li>
                <li [translate]="'HOME.price_5_detail_2'"></li>
                <li [translate]="'HOME.price_5_detail_3'"></li>
                <li [translate]="'HOME.price_5_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12">
          <div class="card">
            <!-- <div class="card-ribbon">
            <span>most popular</span>
        </div> -->
            <!-- <div class="card-title">
              <h4>XL</h4>
            </div> -->
            <div class="card-price">
              <h1>
                <span [translate]="'HOME.price_6_1'"></span><br>
                <span [translate]="'HOME.price_6_2'"></span>
              </h1>

              <h3 [translate]="'HOME.permonth'">
              </h3>
            </div>
            <div class="card-description">
              <ul>
                <li [translate]="'HOME.price_6_detail_1'"></li>
                <li [translate]="'HOME.price_6_detail_2'"></li>
                <li [translate]="'HOME.price_6_detail_3'"></li>
                <li [translate]="'HOME.price_6_detail_4'"></li>
              </ul>
            </div>
            <div class="card-action">
              <button routerLink="/contact" type="button" [translate]="'HOME.price_submit'"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Pricing Packages -->

  <app-footer></app-footer>
</div>
