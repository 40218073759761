<div class="container-fullwidth body-con">

  <div class="container mt-5">

    <div class="col-md-12 text-center mt-5">
      <h4 class="bold my-5" [translate]="'PrivacyPolicy.topic_head'"></h4>
    </div>

    <div class="col-md-12">
      <p class="sub-text ">
        <!-- <span [translate]="'PrivacyPolicy.topic_detail_1_start'"></span>
        <a routerLink="/terms-of-service" [translate]="'PrivacyPolicy.topic_detail_1_click'"></a>
        <span [translate]="'PrivacyPolicy.topic_detail_1_end'"></span> -->
      </p>

      <p class="my-5" [translate]="'PrivacyPolicy.topic_detail_2'"></p>
    </div>

    <div class="col-md-12 text-center my-5">
      <p [translate]="'PrivacyPolicy.topic_detail_3'"></p>
      <p [translate]="'PrivacyPolicy.topic_detail_4'"></p>

    </div>

    <div class="col-md-12">
      <p [translate]="'PrivacyPolicy.topic_detail_6'"></p>

    </div>

  </div>

  <div class="container">
    <div class="col-md-12 text-center my-5">
      <h4 [translate]="'TOS.pada_topic_head'" style="font-weight: bold;"></h4>
      <p [translate]="'TOS.topic_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_1_head'"></h4>
      <h4 [translate]="'TOS.tos_1_detail'"></h4>

      <div class="col-md-12 pl-0">
          <ul class="none-style">

            <li class="my-2" [translate]="'TOS.pada_tos_1_list_1'"></li>
            <li class="my-2" [translate]="'TOS.tos_1_list_2'"></li>
            <li class="my-2" [translate]="'TOS.tos_1_list_3'"></li>
            <li class="my-2" [translate]="'TOS.tos_1_list_4'"></li>
            <li class="my-2" [translate]="'TOS.tos_1_list_5'"></li>
            <li class="my-2" [translate]="'TOS.tos_1_list_6'"></li>
            <li class="my-2" [translate]="'TOS.tos_1_list_7'"></li>
            <li class="my-2" [translate]="'TOS.tos_1_list_8'"></li>

          </ul>
      </div>

    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_2_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_2_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_3_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_3_detail'"></p>

      <div class="col-md-12 pl-0">
        <ul class="none-style">
          <li class="my-2" [translate]="'TOS.tos_3_list_01'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_02'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_03'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_04'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_05'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_06'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_07'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_08'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_09'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_10'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_11'"></li>
          <li class="my-2" [translate]="'TOS.tos_3_list_12'"></li>
        </ul>
      </div>

    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_4_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_4_detail'"></p>

      <div class="col-md-12 pl-0">
        <ul class="none-style">

          <li class="my-2" [translate]="'TOS.tos_4_list_1'"></li>
          <li class="my-2" [translate]="'TOS.tos_4_list_2'"></li>
          <li class="my-2" [translate]="'TOS.tos_4_list_3'"></li>
          <li class="my-2" [translate]="'TOS.tos_4_list_4'"></li>
          <li class="my-2" [translate]="'TOS.tos_4_list_5'"></li>

        </ul>
        </div>

    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_5_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_5_detail_1'"></p>
      <p class="sub-text" [translate]="'TOS.tos_5_detail_2'"></p>
      <p class="sub-text" [translate]="'TOS.tos_5_detail_3'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_6_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_6_detail_1'"></p>

      <div class="col-md-12 pl-0">
        <ul class="none-style">
          <li class="my-2" [translate]="'TOS.tos_6_list_1'"></li>
          <li class="my-2" [translate]="'TOS.tos_6_list_2'"></li>
          <li class="my-2" [translate]="'TOS.tos_6_list_3'"></li>
        </ul>
      </div>

      <p class="sub-text" [translate]="'TOS.tos_6_detail_2'"></p>

      <p class="sub-text" [translate]="'TOS.tos_6_detail_3'"></p>

    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_7_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_7_detail'"></p>

      <div class="col-md-12 pl-0">
        <ul class="none-style">
          <li class="my-2" [translate]="'TOS.tos_7_list_1'"></li>
          <li class="my-2" [translate]="'TOS.tos_7_list_2'"></li>
          <li class="my-2" [translate]="'TOS.tos_7_list_3'"></li>
          <li class="my-2" [translate]="'TOS.tos_7_list_4'"></li>
          <li class="my-2" [translate]="'TOS.tos_7_list_5'"></li>
        </ul>
      </div>

    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_8_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_8_detail'"></p>

      <div class="col-md-12 pl-0">
        <ul class="none-style">
          <li class="my-2" [translate]="'TOS.tos_8_list_1'"></li>
          <li class="my-2" [translate]="'TOS.tos_8_list_2'"></li>
          <li class="my-2" [translate]="'TOS.tos_8_list_3'"></li>
          <li class="my-2" [translate]="'TOS.tos_8_list_4'"></li>
          <li class="my-2" [translate]="'TOS.tos_8_list_5'"></li>
          <li class="my-2" [translate]="'TOS.tos_8_list_6'"></li>
        </ul>
      </div>

    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_9_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_9_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_10_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_10_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_11_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_11_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_12_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_12_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_13_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_13_detail_1'"></p>
      <p class="sub-text" [translate]="'TOS.tos_13_detail_2'"></p>

    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_14_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_14_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_15_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_15_detail'"></p>
      <div class="col-md-12 pl-0">
        <ul class="none-style">
          <li class="my-2" [translate]="'TOS.tos_15_list_1'"></li>
          <li class="my-2" [translate]="'TOS.tos_15_list_2'"></li>
          <li class="my-2" [translate]="'TOS.tos_15_list_3'"></li>
        </ul>
      </div>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_16_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_16_detail_1'"></p>
      <p class="sub-text" [translate]="'TOS.tos_16_detail_2'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_17_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_17_detail'"></p>
      <div class="col-md-12 pl-0">
        <ul class="none-style">
          <li class="my-2" [translate]="'TOS.tos_17_list_1'"></li>
          <li class="my-2" [translate]="'TOS.tos_17_list_2'"></li>
          <li class="my-2" [translate]="'TOS.tos_17_list_3'"></li>
          <li class="my-2" [translate]="'TOS.tos_17_list_4'"></li>
        </ul>
      </div>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_18_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_18_detail_1'"></p>

      <div class="col-md-12 pl-0">
        <ul class="none-style">
          <li class="my-2" [translate]="'TOS.tos_18_list_1_1'"></li>
          <li class="my-2" [translate]="'TOS.tos_18_list_1_2'"></li>
          <li class="my-2" [translate]="'TOS.tos_18_list_1_3'"></li>
          <li class="my-2" [translate]="'TOS.tos_18_list_1_4'"></li>

        </ul>
      </div>

      <p class="sub-text" [translate]="'TOS.tos_18_detail_2'"></p>

      <div class="col-md-12 pl-0">
        <ul class="none-style">
          <li class="my-2" [translate]="'TOS.tos_18_list_2_1'"></li>
          <li class="my-2" [translate]="'TOS.tos_18_list_2_2'"></li>
          <li class="my-2" [translate]="'TOS.tos_18_list_2_3'"></li>
        </ul>
      </div>

      <p class="sub-text" [translate]="'TOS.tos_18_detail_3'"></p>

    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_19_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_19_detail'"></p>

      <div class="col-md-12 pl-0">
        <ul class="none-style">
          <li class="my-2" [translate]="'TOS.tos_19_list_1'"></li>
          <li class="my-2" [translate]="'TOS.tos_19_list_2'"></li>
          <li class="my-2" [translate]="'TOS.tos_19_list_3'"></li>
          <li class="my-2" [translate]="'TOS.tos_19_list_4'"></li>
        </ul>
      </div>

    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_20_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_20_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_21_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_21_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_22_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_22_detail'"></p>

      <div class="col-md-12 pl-0">
        <ul class="none-style">
          <li class="my-2" [translate]="'TOS.tos_22_list_1'"></li>
          <li class="my-2" [translate]="'TOS.tos_22_list_2'"></li>
          <li class="my-2" [translate]="'TOS.tos_22_list_3'"></li>
          <li class="my-2" [translate]="'TOS.tos_22_list_4'"></li>
        </ul>
      </div>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_23_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_23_detail_1'"></p>
      <p class="sub-text" [translate]="'TOS.tos_23_detail_2'"></p>

    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_24_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_24_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_25_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_25_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_26_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_26_detail'"></p>
    </div>


    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_27_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_27_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_28_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_28_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_29_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_29_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_30_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_30_detail'"></p>
    </div>

    <div class="col-md-12 my-5">
      <h4 class="bold" [translate]="'TOS.tos_31_head'"></h4>
      <p class="sub-text" [translate]="'TOS.tos_31_detail'"></p>
    </div>
  </div>

</div>
