<div class="container-fullwidth">
  <app-nav></app-nav>
  <div class=" col-sm-12 col-md-12 col-xl-12 banner" id="banner" style="background-image:url('assets/img/background/BG-2.jpeg');" >
    <div class="row">

      <div class="col-md-2"></div>
      <div class="col-xl-5 mt-5 white" style="padding-top: 10%;padding-bottom: 10%;">
        <h2 [translate]="'PARTNER.banner_head'"></h2>
        <p [translate]="'PARTNER.banner_detail'"></p>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-12 col-lg-12 col-xl-8">
        <div class="col-md-12 py-3">
          <img [src]="'assets/img/partner/partner_img.png'" width="100%" height="auto" alt="">
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
  <h2 class="text-center pt-5" [translate]="'PARTNER.form_head'"></h2>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-12 col-lg-12 col-xl-6 card shadow  p-5">

        <form action="">

          <div class="form-group">
            <label for="firstname">First Name (required)</label>
            <input type="text" class="form-control" placeholder="Firstname">
          </div>

          <div class="form-group">
            <label for="lastname">Last Name (required)</label>
            <input type="text" class="form-control" placeholder="Lastname">
          </div>

          <div class="form-group">
            <label for="email">Email Address (required)</label>
            <input type="text" class="form-control" placeholder="example@email.com">
          </div>

          <div class="form-group">
            <label for="companyname">Company Name (required)</label>
            <input type="text" class="form-control" placeholder="Company Name">
          </div>

          <div class="form-group">
            <label for="phonenumber">Phone Number (required)</label>
            <input type="text" class="form-control" placeholder="Phone Number">
          </div>

          <div class="form-group">
            <label for="website">Website (required)</label>
            <input type="text" class="form-control" placeholder="Website URL">
          </div>

          <div class="form-group">
            <label for="postaladdress">Postal Address (required)</label>
            <input type="text" class="form-control" placeholder="Postal Address">
          </div>

          <div class="form-group">
            <label for="country">Your Country (required)</label>
            <input type="text" class="form-control" placeholder="Your Country">
          </div>

          <div class="form-group my-4">
            <label for="">What type of Partner are you interested in? (required)</label>
            <div class="form-row">
              <div class="form-check mx-2">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  Reseller
                </label>
              </div>
              <div class="form-check mx-2">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  Integration Partner
                </label>
              </div>
              <div class="form-check mx-2">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  Other
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="nature">Nature of your Business (required)</label>
            <input type="text" class="form-control" placeholder="Nature of your Business">
          </div>

          <div class="form-group">
            <label for="product">The Product You Want to Sell (required)</label>
            <input type="text" class="form-control" placeholder="The Product You Want to Sell">
          </div>

          <div class="form-group">
            <label for="otherproduct">Other Products you are selling (required)</label>
            <input type="text" class="form-control" placeholder="Other Products you are selling">
          </div>

          <div class="form-group">
            <label for="haveproduct">Other Products You Have (required)</label>
            <input type="text" class="form-control" placeholder="Other Products You Have">
          </div>

          <div class="form-group text-center">
            <button class="btn btn-success">Send</button>
          </div>

        </form>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
