

  <button id="backTop" (click)="scrollToElement('banner')" class="shadow-lg backTopBtn text-center">
    <img width="40px" height="auto" src="assets/img/arrow-top.png" alt="" style="align-self: center;">
  </button>

<nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-transperant p-0">
  <a class="navbar-brand pl-5"><img class="img-fluid img-responsive" [src]="'assets/img/logo-high.png'" alt="" width="55px" height="auto"></a>
  <h1 class="white m-0 d-none d-lg-block" >KINT</h1>
  <button class="navbar-toggler mx-2" type="button" (click)="toggleNavbar = !toggleNavbar; bgNavbarChange(false)" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
  <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse py-2" #navbar_collapse id="navbarColor01" [ngbCollapse]="toggleNavbar" style="place-content: center;">

    <ul  class="navbar-nav kint-nav ml-auto">

      <li class="nav-item py-md-2 mx-xl-3 mx-md-2">
        <a routerLink="/home" class="navtext align-self-center white" [translate]="'NAV.home'"></a>
      </li>

      <li class="nav-item py-md-2 mx-xl-3 mx-md-2">
          <a routerLink="/features" class="navtext align-self-center white" [translate]="'NAV.feature'"></a>
      </li>

      <li class="nav-item py-md-2 mx-xl-3 mx-md-2">
          <a routerLink="/solutions" class="navtext align-self-center white" [translate]="'NAV.solution'"></a>
      </li>

      <li class="nav-item py-md-2 mx-xl-3 mx-md-2">
          <a routerLink="/pricing" class="navtext align-self-center white" [translate]="'NAV.pricing'"></a>
      </li>

      <li class="nav-item py-md-2 mx-xl-3 mx-md-2 dropdown">
          <button class="btn navtext align-self-center white p-0" [translate]="'NAV.about'"></button>
          <div class="dropdown-content">
            <a routerLink="/about" [translate]="'NAV.about_us'"></a>
            <a routerLink="/partner" [translate]="'NAV.partners'"></a>
          </div>
      </li>

      <li class="nav-item py-md-2 mx-xl-3 mx-md-2">
          <a routerLink="/contact" class="navtext align-self-center white" [translate]="'NAV.contact_us'"></a>
      </li>

      <li class="nav-item py-md-2 mx-xl-3 mx-md-2">
          <a href="https://backend.kintapp.com/login" target="_blank" class="navtext align-self-center white" [translate]="'NAV.login'"></a>
      </li>

      <li class="nav-item py-md-2 mx-xl-3 mx-md-2 d-none d-xl-block">
        <a routerLink="/contact" class="ml-3 btn custom-btn" style="background-color: rgba(255, 255, 255, 0.1);color: white;border-color: white;">Book Demo</a>
      </li>
    </ul>
    <hr style="border-top: 2px solid white;">
    <div class="col-md-auto py-md-2 mx-1 text-center">
      <span>
        <h5>
          <a id="en" style="cursor: pointer;color: white;" class="lang_text" (click)="changeLanguage('en')">English</a>
          |
          <a id="th" style="cursor: pointer;color: white;" class="lang_text" (click)="changeLanguage('th')">ไทย</a>

        </h5>

      </span>
    </div>

  </div>
</nav>
