import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacypolicy-pada',
  templateUrl: './privacypolicy-pada.component.html',
  styleUrls: ['./privacypolicy-pada.component.css']
})
export class PrivacypolicyPadaComponent implements OnInit {
  translate: TranslateService;

  constructor(translate: TranslateService) {
    this.translate = translate;
    this.translate.setDefaultLang('en');
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);

  }

  ngOnInit(): void {
  }

}
