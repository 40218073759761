<div class="container-fullwidth body-con">
  <app-nav></app-nav>

  <div class=" col-sm-12 col-md-12 col-xl-12 banner" id="banner" style="background-image:url('assets/img/background/BG-2.jpeg');" >
    <div class="row">

      <div class="col-md-2"></div>
      <div class="col-xl-5 mt-5 white banner-text" style="padding-top: 10%;padding-bottom: 10%;">
        <h2 class="white" [translate]="'PrivacyPolicy.banner_head'"></h2>
        <p></p>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

  <div class="container mt-5">

    <div class="col-md-12 text-center mt-5">
      <h4 class="bold my-5" [translate]="'PrivacyPolicy.topic_head'"></h4>
    </div>

    <div class="col-md-12">
      <p class="sub-text ">
        <span [translate]="'PrivacyPolicy.topic_detail_1_start'"></span>
        <a routerLink="/terms-of-service" [translate]="'PrivacyPolicy.topic_detail_1_click'"></a>
        <span [translate]="'PrivacyPolicy.topic_detail_1_end'"></span>
      </p>

      <p class="my-5" [translate]="'PrivacyPolicy.topic_detail_2'"></p>
    </div>

    <div class="col-md-12 text-center my-5">
      <p [translate]="'PrivacyPolicy.topic_detail_3'"></p>
      <p [translate]="'PrivacyPolicy.topic_detail_4'"></p>

    </div>

    <div class="col-md-12">
      <p [translate]="'PrivacyPolicy.topic_detail_5'"></p>

    </div>

  </div>


  <app-footer></app-footer>
</div>
