<div class="container-fullwidth body-con">
<app-nav></app-nav>

  <div class=" col-sm-12 col-md-12 col-xl-12 banner" id="banner" style="background-image:url('assets/img/background/BG-2.jpeg');" >
    <div class="row">

      <div class="col-md-2"></div>
      <div class="col-xl-5 mt-5 white banner-text" style="padding-top: 10%;padding-bottom: 10%;">
        <h2 [translate]="'FEATURES.banner_head'"></h2>
        <p [translate]="'FEATURES.banner_detail'"></p>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

  <!-- Video -->
  <div class="col-xl-12">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-12 col-lg-12 col-xl-6 text-center my-5">
        <!-- <video controls autoplay loop muted width="100%" height="auto" style="border-radius: 15px;">
          <source [src]="'assets/video/Intro.mp4'" type="video/mp4">
        </video> -->
        <video autoplay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true" style="width: 100%; height:auto;border-radius: 15px;">
          <source src="assets/video/Intro.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
  <!-- Video -->

  <div class="col-xl-12 pt-xl-5">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-12 col-lg-12 col-xl-8">
        <div class="row">

          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 py-5 align-self-center">
            <img [src]="'assets/img/features/1.jpg'" width="100%" height="auto">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-5 align-self-center">
            <h2 [translate]="'FEATURES.feature_1_head'" class="py-5"></h2>
            <p [translate]="'FEATURES.feature_1_detail'"></p>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 py-5 align-self-center">
            <img [src]="'assets/img/features/2.jpg'" width="100%" height="auto">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 align-self-center pb-5">
            <h2 [translate]="'FEATURES.feature_2_head'" class="py-5"></h2>
            <p [translate]="'FEATURES.feature_2_detail_1'"></p>
            <p [translate]="'FEATURES.feature_2_detail_2'"></p>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 py-5 align-self-center text-center">
            <img [src]="'assets/img/features/3.gif'" width="80%" height="auto">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 align-self-center pb-5">
            <h2 [translate]="'FEATURES.feature_3_head'" class="py-5"></h2>
            <p [translate]="'FEATURES.feature_3_detail_1'"></p>
            <p [translate]="'FEATURES.feature_3_detail_2'"></p>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 py-5 align-self-center">
            <img [src]="'assets/img/features/4.jpg'" width="100%" height="auto">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 align-self-center pb-5">
            <h2 [translate]="'FEATURES.feature_4_head'" class="py-5"></h2>
            <p [translate]="'FEATURES.feature_4_detail_1'"></p>
            <p [translate]="'FEATURES.feature_4_detail_2'"></p>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 py-5 align-self-center">
            <img [src]="'assets/img/features/5.jpg'" width="100%" height="auto">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 align-self-center pb-5">
            <h2 [translate]="'FEATURES.feature_5_head'" class="py-5"></h2>
            <p [translate]="'FEATURES.feature_5_detail_1'"></p>
            <p [translate]="'FEATURES.feature_5_detail_2'"></p>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 py-5 align-self-center">
            <img [src]="'assets/img/features/6.jpg'" width="100%" height="auto">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 align-self-center pb-5">
            <h2 [translate]="'FEATURES.feature_6_head'" class="py-5"></h2>
            <p [translate]="'FEATURES.feature_6_detail_1'"></p>
            <p [translate]="'FEATURES.feature_6_detail_2'"></p>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 py-5 align-self-center">
            <img [src]="'assets/img/features/7.jpg'" width="100%" height="auto">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 align-self-center pb-5">
            <h2 [translate]="'FEATURES.feature_7_head'" class="py-5"></h2>
            <p [translate]="'FEATURES.feature_7_detail_1'"></p>
            <p [translate]="'FEATURES.feature_7_detail_2'"></p>
          </div>

        </div>



      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

  <div class="container-fluid mt-5 d-none d-md-block">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-12 col-lg-12 col-xl-8">

          <owl-carousel [options]="SlideOptions" [carouselClasses]="['owl-theme', 'sliding']" >
            <div class="item" *ngFor="let img of images">
            <div class="mx-2" style="align-content: center">
              <img style="height: 50px;width:auto"src={{img}}/>
            </div>
            </div>
          </owl-carousel>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>


<app-footer></app-footer>

</div>
